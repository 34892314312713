select:invalid {
    color: gray;
}

.popover.bs-popover-auto.show,
.popover-body {
    max-width: 768px;
}

.custom-icon {
    border-radius: 50%;
    width: 22px;
    display: inline-block;
    padding-left: 4px;
}
