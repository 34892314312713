.gray-bg {
    background-color: gray;
}

.white-bg {
    background-color: white;
}

.navigation-left-panel {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
}

.full-height {
    height: calc(100vh - 50px);
}

.height-128 {
    height: calc(100vh - 128px);
}

table.table {
    margin-bottom: 0;
}

.p-3-top {
    padding: 1rem 0 0 0 !important;
}

.p-3-much-left {
    padding: 1rem 1rem 1rem 2rem !important;
}

.p-4-no-bottom {
    padding: 1.5rem 1.5rem 0 1.5rem !important;
}

.btn-black, .btn-black:hover {
    background-color: black;
    color: white;
}

.btn-block {
    display: block;
}

.no-padding {
    padding: 0;
}

.no-side-margin {
    margin-left: 0;
    margin-right: 0;
}

.nav-item-custom {
    margin: 0!important;
    padding: 15px 40px;
}

.nav-item-custom-selected {
    background-color: #E1E3E6;
    border-right-color: black;
    border-right-width: 3px;
    border-right-style: solid;
}

.right-side-container {
    max-width: calc(100% - 250px);
}

.btn-custom {
    border: none;
}

.bg-lightgray {
    background-color: lightgray;
    overflow-y: auto;
}

.bg-white {
    background-color: white;
}

.bg-border {
    background-color: #E1E3E6;
}

.form-check-input-black {
    background-color: lightgray;
    border-color: lightgray;
    accent-color: white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='rgb%28255, 255, 255%29'/%3e%3c/svg%3e") ! important;
}

.form-check-input-black:checked {
    background-color: black;
    border-color: black;
}

.overflow-x-scroll {
    overflow-x: scroll;
}