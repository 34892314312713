.color-gray {
    color: gray;
}

.user-info-header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    background-color: black;
    background-image: url('./../../../public/img/logo.png');
}

.user-info-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}